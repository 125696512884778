/**/
@font-face {
    font-family: "Roboto";
    src: url("../fonts/roboto/RobotoRegular.eot");
    src: url("../fonts/roboto/RobotoRegular.eot?#iefix")format("embedded-opentype"),
    url("../fonts/roboto/RobotoRegular.woff") format("woff"),
    url("../fonts/roboto/RobotoRegular.ttf") format("truetype");
    font-style: normal;
    font-weight: normal;
}

@font-face {
    font-family: "Roboto";
    src: url("../fonts/roboto/RobotoBold.eot");
    src: url("../fonts/roboto/RobotoBold.eot?#iefix")format("embedded-opentype"),
    url("../fonts/roboto/RobotoBold.woff") format("woff"),
    url("../fonts/roboto/RobotoBold.ttf") format("truetype");
    font-style: normal;
    font-weight: bold;
}
/**/
@font-face {
    font-family: "FontAwesome";
    src: url("../fonts/fontawesome/fontawesome-webfont.eot?v=4.7.0");
    src: url("../fonts/fontawesome/fontawesome-webfont.eot?#iefix&v=4.7.0") format("embedded-opentype"),
    url("../fonts/fontawesome/fontawesome-webfont.woff2?v=4.7.0") format("woff2"),
    url("../fonts/fontawesome/fontawesome-webfont.woff?v=4.7.0") format("woff"),
    url("../fonts/fontawesome/fontawesome-webfont.ttf?v=4.7.0") format("truetype"),
    url("../fonts/fontawesome/fontawesome-webfont.svg?v=4.7.0#fontawesomeregular") format("svg");
    font-weight: normal;
    font-style: normal;
}
/**/
html, body {
    position: relative;
    margin: 0px;
    padding: 0px;
    width: 100%;
    height: 100%;
    overflow-x: hidden;
    overflow-y: auto;
    background-color: #ffffff;
}
/**/
body::-webkit-scrollbar-button {
    background-image: url('');
    background-repeat: no-repeat;
    width: 4px;
    height: 0px;
}

body::-webkit-scrollbar-track {
    background-color: #d2d7db;
}

body::-webkit-scrollbar-thumb {
    -webkit-border-radius: 5px;
    border-radius: 2px;
    background-color: #9ab1c4;
    background-position: center;
    background-repeat: no-repeat;
}

body::-webkit-resizer{
    background-image: url('');
    background-repeat: no-repeat;
    width: 5px;
    height: 0px
}

body::-webkit-scrollbar{
    width: 8px;
}
/**/
div {
    margin: 0px;
    padding: 0px;
    display: block;
}

a {
    color: #3a71af;
    text-decoration: none;
}

a:hover {
    text-decoration: underline;
}

* {
    font-family: 'Roboto';
    outline: none;
	-webkit-appearance: none;
}

p {
    margin-top: 0px;
    margin-bottom: 20px;
}

p sup {
    font-size: 10px;
}

h1 {

}

.mobile{
    display: none;
}

.desktop{
	display: block;
}

.hide{
    display: none !important;
}
/**/
br {
    font-size: 0px;
    line-height: 0px;
}

::selection {
    background: #3a71af;
    color: #ffffff;
}

::-moz-selection {
    background: #3a71af;
    color: #ffffff;
}

img {
    border: none;
}

img[src=""] {
    display: none;
}

table{
    border-collapse: collapse;
    border: none;
}
/**/
body.compensate-for-scrollbar {
    overflow: visible;
}

.fancybox-content{
    padding: 20px;
}
/**/
#wrap_page {
    overflow: hidden;
    position: relative;
    min-width: 1240px;

    font-size: 16px;
    line-height: 24px;
    font-weight: 400;
    color: #343434;
}
/**/
#page_left{
    position: fixed;
    left: 0px;
    top: 0px;
    width: 240px;
    height: 100%;
    padding: 25px 25px 25px 25px;
    box-sizing: border-box;
    background-color: #3a71af;
    overflow-x: hidden;
    overflow-y: auto;
}

#page_left::-webkit-scrollbar-button {
    background-image: url('');
    background-repeat: no-repeat;
    width: 4px;
    height: 0px
}

#page_left::-webkit-scrollbar-track {
    background-color: #d2d7db;
}

#page_left::-webkit-scrollbar-thumb {
    -webkit-border-radius: 5px;
    border-radius: 2px;
    background-color: #9ab1c4;
    background-position: center;
    background-repeat: no-repeat;
}

#page_left::-webkit-resizer{
    background-image: url('');
    background-repeat: no-repeat;
    width: 5px;
    height: 0px
}

#page_left::-webkit-scrollbar{
    width: 8px;
}
/**/
#site_logo{
    font-size: 0px;
    text-align: center;
    margin-bottom: 10px;
}

#site_logo img{
    display: block;
    width: 60px;
    margin-left: 60px;
}

#site_name{
    text-align: center;
    color: #eceff4;
    font-size: 12px;
    line-height: 14px;
    margin-bottom: 7px;
}

#site_desc{
    text-align: center;
    color: #eceff4;
    font-size: 12px;
    line-height: 14px;
    margin-bottom: 20px;
    padding-top: 5px;
    border-top: 1px solid #9ab1c4;
}
/**/
#site_time{
    color: #eceff4;
    margin-bottom: 10px;
    text-align: center;
}

#site_time span{
    display: inline-block;
    background-color: #9ab1c4;
    padding: 2px 8px 3px 9px;
    border-radius: 8px;
    font-size: 16px;
    line-height: 20px;
}
/**/
#site_user{
    color: #eceff4;
    font-size: 16px;
    line-height: 20px;
    margin-bottom: 20px;
    text-align: center;
}
/**/
.site_menu{
    padding-top: 10px;
    border-top: 1px solid #9ab1c4;
    margin-bottom: 10px;
    margin-left: -25px;
    margin-right: -25px;
}

.site_menu ul, .site_menu li{
    margin: 0px;
    padding: 0px;
    list-style: none;
}

.site_menu a{
    font-size: 0px;
    display: block;
    padding: 4px 25px 4px 25px;
    text-decoration: none;
    white-space: nowrap;
    overflow: hidden;
}

.site_menu a:hover, .site_menu a.active{
    background-color: #1f548f;
}

.site_menu a .pcl_p1, .site_menu a .pcl_p2{
    display: inline-block;
    vertical-align: middle;
}

.site_menu a .pcl_p1{
    width: 40px;
    text-align: center;
    font-family: "FontAwesome";
    color: #9ab1c4;
    font-size: 16px;
}

.site_menu a .pcl_p2{
    color: #ffffff;
    font-size: 15px;
}
/**/
#site_menu{

}

#site_user_menu{

}
/**/
#page_right{
    position: relative;
    margin-left: 240px;
    padding: 35px;
    box-sizing: border-box;
}
/**/
#page_content{
    position: relative;
}

#page_content_title{
    font-size: 20px;
    line-height: 24px;
    margin-bottom: 25px;
}
/**/
.btn{
    display: inline-block;
    height: 25px;
    line-height: 23px;
    font-size: 14px;
    padding: 0px 12px 0px 12px;
    border-radius: 6px;
    color: #ffffff;
    background-color: #9ab1c4;
    text-decoration: none;
}

.btn:hover{
    background-color: #3a71af;
    text-decoration: none;
}

.btn_with_icon{

}

.btn_with_icon span{
    display: inline-block;
    vertical-align: middle;
    font-family: "FontAwesome";
    margin-right: 4px;
    font-size: 14px;
    color: #ffffff;
}

.btn_right_top_panel{
    position: absolute;
    right: 0px;
    top: 0px;
    text-align: right;
}

.btn_right_top_panel .btn{
    margin-left: 5px;
    margin-bottom: 8px;
}

.btn_right_top_panel_part{
    display: inline-block;
    vertical-align: top;
    margin-right: 35px;
}
/**/
input[type="text"],
input[type="search"],
input[type="password"],
input[type="email"],
input[type="date"],
input[type="number"],
textarea,
select
{
    display: inline-block;
    font-size: 14px;
    padding: 0px 5px 0px 5px;
    height: 29px;
    color: #343434;
    background-color: #ffffff;
    border: 1px solid #d2d7db;
    border-radius: 4px;
    box-sizing: border-box;
}

select[multiple]{
    height: 120px;
}

textarea{
    padding: 3px 5px 3px 5px;
    height: 72px;
}

select{
    cursor: pointer;
    appearance: auto;
}

input[type="checkbox"]{
    -webkit-appearance: checkbox;
    display: inline-block;
    font-size: 14px;
    padding: 0px;
    width: 16px;
    height: 16px;
    color: #343434;
    background-color: #ffffff;
    border: 1px solid #d2d7db;
    border-radius: 4px;
    box-sizing: border-box;
}

input[type="text"]:focus,
input[type="search"]:focus,
input[type="password"]:focus,
input[type="email"]:focus,
input[type="date"]:focus,
input[type="number"]:focus,
textarea:focus,
select:focus{
    background-color: #f5f5f5;
}

input[type="submit"],
input[type="button"]
{
    cursor: pointer;
    border: none;
    display: inline-block;
    height: 27px;
    font-size: 14px;
    padding: 0px 12px 0px 12px;
    border-radius: 5px;
    color: #ffffff;
    background-color: #3a71af;
}

input[type="submit"]:hover,
input[type="button"]:hover
{
    background-color: #1f548f;
}

.edit_table input[type="text"],
.edit_table input[type="password"],
.edit_table input[type="email"],
.edit_table input[type="date"],
.edit_table textarea
{
    width: 320px;
}

.edit_table select{
    width: 320px;
}

.zv{
    color: #ff0000;
}

.input_file_img_view{
    width: 200px;
    font-size: 0;
}

.input_file_img_view img{
    width: 100%;
    height: auto;
}
/**/
.field_wrap_wrap{
    position: relative;
    width: 320px;
    padding-left: 28px;
    padding-top: 2px;
    box-sizing: border-box;
    line-height: 18px;
    font-size: 14px;
    margin-bottom: 10px;
}

.field_wrap_wrap input{
    position: absolute;
    left: 0px;
    top: 0px;
}
/**/
.data_table_div{
    position: relative;
    overflow-x: auto;
    padding-bottom: 5px;
}

.data_table_div::-webkit-scrollbar {
    -webkit-appearance: none
}

.data_table_div::-webkit-scrollbar:vertical {
    width: 12px
}

.data_table_div::-webkit-scrollbar:horizontal {
    height: 10px
}

.data_table_div::-webkit-scrollbar-thumb {
    background-color: rgba(10,24,50,.2);
    border-radius: 2px;
    border: 2px solid #fff
}

.data_table_div::-webkit-scrollbar-track {
    border-radius: 10px;
    background-color: #fff
}
/**/
.data_table_div table{
    border-collapse: collapse;
}

.data_table_div td{
    padding: 8px 10px 8px 10px;
    text-align: left;
    vertical-align: top;
    border: 1px solid #d2d7db;
    font-size: 14px;
    line-height: 16px;
}

.data_table_div thead{
    border-bottom: 1px solid #dddddd;
}

.data_table_div thead td{
    font-weight: normal;
    color: #343434;
    background-color: #eceff4;
}

.data_table_div thead td span{
    font-weight: bold;
}

.data_table_div tbody td{
    background-color: #ffffff;
}

.data_table_div tbody tr:hover td{
    background-color: #eceff4;
}
/**/
.data_table_div_in_widget table{
    width: 100%;
}

.data_table_div_in_widget td{
    border: none;
    border-bottom: 1px solid #eceff4;
}

.data_table_div_in_widget thead td {
    background-color: transparent;
    color: #616a73;
}

/**/
.paginator_td{
    background-color: transparent !important;
    border: none !important;
    padding: 0 !important;
}

.paginator{
    padding: 20px 0px 15px 0px;
}

.paginator_info{
    display: inline-block;
    vertical-align: middle;
    margin-right: 150px;
}

.paginator_title{
    display: inline-block;
    vertical-align: middle;
}

.paginator ul, .paginator li{
    margin: 0px !important;
    padding: 0px !important;
    list-style: none;
}

.paginator ul, .paginator li{
    font-size: 0;
    display: inline-block;
    vertical-align: middle;
}

.paginator li span{
    display: block;
    box-sizing: border-box;
    margin: 0px 5px 0px 5px;
    width: 24px;
    height: 24px;
    line-height: 24px;
    font-size: 14px;
    text-align: center;
    background-color: #eceff4;
    color: #565656;
    border-radius: 4px;
    cursor: pointer;
}

.paginator li span.active{
    background-color: #9ab1c4;
    color: #ffffff;
    text-decoration: none;
}
/**/
.action_link_list{
    text-align: center;
    white-space: normal;
    font-size: 0px;
    margin-left: -10px;
    margin-right: -10px;
}

.action_link{
    display: inline-block;
    vertical-align: middle;
    margin: 0px 10px 0px 10px;
    font-size: 14px;
    color: #343434;
    text-decoration: none !important;
    height: 20px;
    line-height: 20px;
}

.action_link span{
    margin-right: 5px;
    display: inline-block;
    vertical-align: top;
    font-family: "FontAwesome";
    color: #343434;
    font-size: 14px;
}

.action_link:hover, .action_link:hover span{
    color: #3a71af;
}
/**/
.action_link_icon_list_razd{
    height: 5px;
}

.action_link_icon_list{
    text-align: center;
    white-space: nowrap;
    font-size: 0px;
}

.action_link_icon{
    display: inline-block;
    vertical-align: middle;
    margin: 0px 5px 0px 5px;
    font-size: 14px;
    color: #343434;
    font-family: "FontAwesome";
    text-decoration: none !important;
}

.action_link_icon:hover{
    color: #3a71af;
}
/**/
.data_table_div_with_filtrs thead td{
    padding-bottom: 33px;
    position: relative;
}

.page_filtr_intable{
    position: absolute;
    left: 0px;
    bottom: 0px;
    width: 100%;
}

.page_filtr_intable > div{
    margin: 3px;
}

.page_filtr_intable input, .page_filtr_intable select{
    width: 100%;
    box-sizing: border-box;
    background: transparent;
    border: 1px solid #ffffff;
    font-size: 14px;
}

.page_filtr_intable input.active, .page_filtr_intable select.active{
    border: 1px solid #ff0000;
}
/**/
/**/
.fancybox-container{
    z-index: 999991;
}

.fancybox-active {
    height: 100%;
}

.fancybox-is-open .fancybox-bg{
    opacity: 0.5;
}

.fancybox-content {
    background-color: transparent;
}

.fancybox-slide--html .fancybox-close-small {
    width: 35px;
    height: 35px;
    padding: 5px;
}
/**/
.modal {
    position: fixed;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    display: none;
    background: rgba(0,0,0, 0.5);
    align-items: center;
    justify-content: center;
    overflow: auto;
}

.modal_content {
    padding: 0px;
    background: transparent;
    border-radius: 0px;
    min-width: 250px;
    max-height: 90%;
}

.modal_content:after{
    content: "";
    display: block;
    height: 10px;
    background: transparent;
}

.modal.active {
    display: flex;
}

.modal_window{
    background-color: #ffffff;
    border: 1px solid #eeeeee;
    box-shadow: 0 1px 3px 0 rgb(0 0 0 / 20%);
    padding: 0px;
    border-radius: 4px;
    margin-bottom: 15px;
}

.modal_window_head{
    position: relative;
    border-bottom: 1px solid #dddddd;
    padding: 10px 40px 10px 15px;
    color: #616a73;
    background-color: #eceff4;
    line-height: 18px;
    font-size: 16px;

    min-height: 39px;
    box-sizing: border-box;
}

.modal_window_close{
    position: absolute;
    right: 15px;
    top: 10px;
    cursor: pointer;
    font-family: "FontAwesome";
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

.modal_window_close:hover{
    color: #3a71af;
}

.modal_window_body{
    padding: 15px;
}
/**/
.edit_table{
    border: none;
}

.edit_table td{
    padding: 0px 20px 20px 0px;
    vertical-align: top;
}

.edit_table > tbody > tr > td:last-child{
    padding-right: 0px;
}

.edit_table > tbody > tr:last-child > td{
    padding-bottom: 0px;
}
/**/
.view_table{

}

.view_table td{
    padding: 7px 10px 8px 10px;
    text-align: left;
    vertical-align: top;
    border: 1px solid #d2d7db;
    font-size: 14px;
    line-height: 16px;
}

.view_table td:nth-child(1){
    background-color: #eceff4;
}

.view_table td:nth-child(2){
    width: 320px;
}
/**/
.vertical_tabs{
    padding-top: 10px;
    padding-bottom: 25px;
}

.vertical_tabs ul, .vertical_tabs li{
    margin: 0px !important;
    padding: 0px !important;
    list-style: none;
    display: block;
}

.vertical_tabs li{
    display: block;
    padding: 10px !important;
    margin-bottom: -1px !important;
    text-decoration: none;
    color: #343434;
    text-align: center;
    border: 1px solid #d2d7db;
    background-color: #eceff4;
    cursor: pointer;
}

.vertical_tabs li.active
{
    background-color: #ffffff;
    font-weight: bold;
}

.vertical_tabs_right{
    border-left: 1px solid #d2d7db;
}

.vertical_tabs_right li{
    text-align: left;
    padding-left: 15px !important;
    margin-left: -1px !important;
}

.vertical_tabs_right li.active{
    background-color: #ffffff;
    border-left: 1px solid #ffffff;
}
/**/
.horizontal_tabs{
    font-size: 0px;
    padding-right: 25px;
    border-bottom: 1px solid #d2d7db;
    margin-bottom: 25px;
}

.horizontal_tabs ul, .horizontal_tabs li{
    margin: 0px !important;
    padding: 0px !important;
    list-style: none;
    display: block;
}

.horizontal_tabs ul{
    position: relative;
    margin-bottom: -1px !important;
}

.horizontal_tabs li{
    display: inline-block;
    margin-right: -1px !important;
    text-decoration: none;
    color: #343434;
    border: 1px solid #d2d7db;
    background-color: #eceff4;
    cursor: pointer;
    font-size: 14px;
}

.horizontal_tabs li.active
{
    background-color: #ffffff;
    font-weight: bold;
    border-bottom: 1px solid #ffffff;
}

.horizontal_tabs li a,
.horizontal_tabs li span
{
    display: block;
    padding: 7px 12px 7px 12px !important;
    text-decoration: none;
    color: #343434;
}

.horizontal_tabs_body_set .horizontal_tabs_body{
    display: none;
}

.horizontal_tabs_body_set .horizontal_tabs_body.active{
    display: block;
}
/**/
.block_panel{
    background-color: #ffffff;
    /*box-shadow: 0 1px 3px 0 rgb(0 0 0 / 20%);*/
    border: 1px solid #eceff4;
    border: 1px solid #d2d7db;
    overflow: hidden;
    border-radius: 4px;
    margin-bottom: 20px;
}

.block_panel_head{
    border-bottom: 1px solid #dddddd;
    padding: 8px 10px 8px 10px;
    color: #616a73;
    background-color: #eceff4;
    line-height: 18px;
    font-size: 16px;
}

.block_panel_head span{
    display: inline-block;
    vertical-align: middle;
    font-family: "FontAwesome";
    margin-right: 10px;
    font-size: 18px;
}

.block_panel_body{
    padding: 8px 10px 8px 10px;
}
/**/
#data_elem_list{
    margin-left: -10px;
    margin-right: -10px;
    text-align: left;
    font-size: 0px;
}

.data_elem_item{
    display: inline-block;
    vertical-align: middle;
    width: 380px;
    padding: 0px 10px 0px 10px;
    box-sizing: border-box;
    text-decoration: none !important;
    font-size: 14px;
}

.data_elem_item_desc{
    margin-bottom: 0px;
}

.data_elem_item_col1{
    width: 100%;
}

.data_elem_item_col2{
    width: 50%;
}
/**/
.page_preloader {
    width: 100px;
    height: 100px;
    border-radius: 50%;
    position: relative;
    margin: 0 auto;
    margin-top: 25px;
    animation: rotate 1s linear infinite
}
.page_preloader::before {
    content: "";
    box-sizing: border-box;
    position: absolute;
    inset: 0px;
    border-radius: 50%;
    border: 5px solid #eceff4;
    animation: prixClipFix 2s linear infinite ;
}

@keyframes rotate {
    100%   {transform: rotate(360deg)}
}

@keyframes prixClipFix {
    0%   {clip-path:polygon(50% 50%,0 0,0 0,0 0,0 0,0 0)}
    25%  {clip-path:polygon(50% 50%,0 0,100% 0,100% 0,100% 0,100% 0)}
    50%  {clip-path:polygon(50% 50%,0 0,100% 0,100% 100%,100% 100%,100% 100%)}
    75%  {clip-path:polygon(50% 50%,0 0,100% 0,100% 100%,0 100%,0 100%)}
    100% {clip-path:polygon(50% 50%,0 0,100% 0,100% 100%,0 100%,0 0)}
}
/**/
.edit_table td .page_preloader {
    display: inline-block;
    vertical-align: middle;
    width: 22px;
    height: 22px;
    margin-top: 0px;
    margin-right: 15px;
}
/**/
.page_preloader1 {
    width: 120px;
    height: 120px;
    border-radius: 50%;
    border: 5px solid #eceff4;
    border-top-color: #d2d7db;
    border-bottom-color: #d2d7db;
    margin: 0 auto;

    animation: rotate 1.5s infinite linear;
}

@keyframes rotate {
    from {
        transform: rotate(0deg) scale(1);
    }
    to {
        transform: rotate(360deg) scale(1);
    }
}
/**/
.form_message{
    padding: 10px 20px;
    margin: 0 auto;
    margin-bottom: 15px;
    border-radius: 5px;
    box-sizing: border-box;
    background-color: #eceff4;
}

.form_message_success{
    background: rgb(24, 139, 48, 0.1);
}

.form_message_error{
    background: rgba(255, 0, 0, 0.1);
}

.form_message_fading,
.form_message[fading="true"]
{
    height: auto;
    overflow: hidden;
    animation: fade1 0.5s 2s forwards;
}



.form_message_title{
    font-weight: bold;
    margin-bottom: 5px;
}

.form_message_text *{
    color: #343434;
}

.form_message_text ul {
    margin: 0px;
    padding-left: 20px;
}

.form_message_text li {
    padding: 0px;
    text-indent: -5px;
}

@keyframes fade1 {
    from {
        opacity: 1;
        position: relative;
    }
    99% {
        opacity: 0;
        position: relative;
    }
    100% {
        opacity: 0;
        position: fixed;
    }
}
/**/
.multifield{
    width: 100%;
}

.multifield textarea{
    display: none;
}

.multifield__tbl{
    width: 100%;
    border-collapse: collapse;
}

.multifield__tbl td{
    cursor: text;
    border: 1px solid #d2d7db;
    padding: 6px 8px 6px 8px;
    font-size: 16px;
    line-height: 18px;
}

.multifield__tbl td:focus{
    background-color: #eeeeee;
}

.multifield__add{
    text-align: center;
    padding: 5px 0px 5px 0px;
}

.multifield__tbl_del{
    cursor: pointer;
    font-family: FontAwesome;
    color: #ff0000;
    text-align: center;
}
/**/
.switch_field{
    text-align: right;
    font-size: 12px;
    cursor: pointer;
    color: #d2d7db;
}

.switch_field span{
    color: #616a73;
}

.switch_field span.active{
    color: #d2d7db;
}
/**/
.no_contractor td{
    background: rgb(24, 139, 48, 0.1) !important;
}
/**/