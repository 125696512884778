/**/
/*
@font-face {
    font-family: "RobotoRegular";
    src: url("../fonts/roboto/RobotoRegular.eot");
    src: url("../fonts/roboto/RobotoRegular.eot?#iefix")format("embedded-opentype"),
    url("../fonts/roboto/RobotoRegular.woff") format("woff"),
    url("../fonts/roboto/RobotoRegular.ttf") format("truetype");
    font-style: normal;
    font-weight: normal;
}
/**/
/*
html, body {
    position: relative;
    margin: 0px;
    padding: 0px;
    width: 100%;
    height: 100%;
    overflow-x: hidden;
    overflow-y: auto;
    background-color: #eceff4;
}
/**/
/*
body::-webkit-scrollbar-button {
    background-image: url('');
    background-repeat: no-repeat;
    width: 4px;
    height: 0px
}

body::-webkit-scrollbar-track {
    background-color: #d2d7db;
}

body::-webkit-scrollbar-thumb {
    -webkit-border-radius: 5px;
    border-radius: 2px;
    background-color: #9ab1c4;
    background-position: center;
    background-repeat: no-repeat;
}

body::-webkit-resizer{
    background-image: url('');
    background-repeat: no-repeat;
    width: 5px;
    height: 0px
}

body::-webkit-scrollbar{
    width: 8px;
}
/**/
/*
div {
    margin: 0px;
    padding: 0px;
    display: block;
}

a {
    color: #3a71af;
    text-decoration: none;
}

a:hover {
    text-decoration: underline;
}

* {
    font-family: 'RobotoRegular';
    outline: none;
    -webkit-appearance: none;
}

p {
    margin-top: 0px;
    margin-bottom: 20px;
}

p sup {
    font-size: 10px;
}

h1 {

}

.mobile{
    display: none;
}

.desktop{
    display: block;
}
/**/
/*
br {
    font-size: 0px;
    line-height: 0px;
}

::selection {
    background: #3a71af;
    color: #ffffff;
}

::-moz-selection {
    background: #3a71af;
    color: #ffffff;
}

img {
    border: none;
}

img[src=""] {
    display: none;
}
/**/

/**/
/*
#wrap_page_auth {
    overflow: hidden;
    position: relative;
    min-width: 1240px;

    font-size: 16px;
    line-height: 24px;
    font-weight: 400;
    color: #343434;

    padding-top: 100px;
    padding-bottom: 100px;
}

#wrap_page_auth:before{
    content: "";
    display: block;
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background-color: #eceff4;
}

 */

#wrap_page_auth {
    overflow: hidden;
    position: fixed;
    width: 100%;
    height: 100%;
    background-color: #eceff4;
    font-size: 16px;
    line-height: 24px;
    font-weight: 400;
    color: #343434;
    display: flex;
    align-items: center;
    justify-content: center;
}
/**/
#page_auth{
    background-color: #ffffff;
    padding: 50px;
    box-sizing: border-box;
    width: 600px;
    margin: 0 auto;
    position: relative;
    /*#eceff4;*/
}
/**/
#page_auth_head{
    margin-bottom: 40px;
    text-align: center;
}

#page_auth_head_logo{
    display: inline-block;
    vertical-align: middle;
    margin-right: 15px;
}

#page_auth_head_logo img{
    display: block;
    width: 70px;
}

#page_auth_head_text{
    display: inline-block;
    vertical-align: middle;
    text-align: left;
}

#page_auth_head_name{
    font-size: 14px;
    line-height: 16px;
    margin-bottom: 7px;
}

#page_auth_head_desc{
    font-size: 14px;
    line-height: 16px;
    padding-top: 5px;
    border-top: 1px solid #9ab1c4;
}
/**/
#page_auth_title{
    text-align: center;
    font-size: 20px;
    line-height: 24px;
    margin-bottom: 20px;
}

input.page_auth_field{
    display: block;
    width: 300px;
    height: 36px;
    box-sizing: border-box;
    background-color: transparent;
    border: 1px solid #9ab1c4;
    border-radius: 6px;
    margin: 0 auto;
    margin-bottom: 15px;
    text-align: center;
    font-size: 16px;
    background-color: #f7f7f7;
}

input.page_auth_field:focus{
    background-color: #f0f0f0;
}

input.page_auth_btn{
    display: block;
    width: 300px;
    height: 36px;
    box-sizing: border-box;
    background-color: #3a71af;
    border: none;
    border-radius: 6px;
    margin: 0 auto;
    color: #ffffff;
    cursor: pointer;
    font-size: 16px;
}

input[type="submit"].page_auth_btn:hover{
    background-color: #1f548f;
}
/**/
#page_auth .form_message{
    width: 300px;
}
/**/